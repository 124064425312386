var Application = function(params){
	var self = this;

	self.csrf = params && params.csrf || {};
	self.user = new User(params && params.user || {});
	self.urls = params && params.urls || {};

	// normalize URLs
	self.urls.ajax = self.urls.ajax || self.urls.base + 'ajax';

	self.ajaxRequest = function(action, params, callback) {
		var requestType = 'POST';
		if(typeof callback === 'string')
			requestType = callback;
		return $.ajax({
			type: requestType,
			url: self.urls.ajax + '/' + action,
			data: $.extend(self.csrf, {data: params||{}}),
			dataType: 'json'
		}).done(function(r){
			if(!r) return;
			if(r.ok)
				callback && callback(r);
			else if(r.message)
				alert(r.message);
		});
	};

	self.injectHtml = function(target, html) {
		var container = document.createElement('div');
		container.innerHTML = html;
		ko.applyBindings(self, container);
		target.innerHTML = container;
	};

	self.auth = function() {
		if(!self.user.isGuest())
			return true;
		var url = self.urls.login;
		if(url.indexOf('?') === -1)
			url += '?';
		else
			url += '&';
		// redirect
		window.location.href = url + 'u=' + encodeURIComponent(window.location.href);

		// popup here ...

		return false;
	};
};