ko.components.register('server-time', {
	viewModel: function(params){
		var self = this;

		self.label = ko.observable(params && params.label || 'Server time');
		self.time = ko.observable(parseInt(params && params.time || 0));

		self.getLabel = function() {
			return self.label();
		};

		self.getTime = function() {
			return (new Date(self.time() * 1000).toLocaleString('en-GB',{timeZone:'UTC'}));
		};

		self.updateTime = function() {
			self.time(self.time() + 1);
		};

		setInterval(self.updateTime, 1000);
	},
	template: "<span class=\"server-time\">\r\n\t<span class=\"time-label\" data-bind=\"html: getLabel()\"></span>:\r\n\t<span class=\"time-value\" data-bind=\"html: getTime()\"></span>\r\n</span>\r\n"
});