ko.components.register('scroll-top', {
	viewModel: function(params){
		var self = this;

		self.showOffset = ko.observable(parseInt(params && params.showOffset || 200));
		self.duration = ko.observable(parseInt(params && params.duration || 500));

		self.currentOffset = ko.observable(0);
		self.savedOffset = ko.observable(0);
		self.scrolling = ko.observable(false);

		self.show = ko.computed(function() {
			return self.savedOffset() || self.currentOffset() > self.showOffset();
		});

		self.isGoUp = function() {
			return (self.currentOffset() > self.showOffset());
		};

		self.getLabel = function() {
			if(self.isGoUp())
				return '&uarr;';
			return '&darr;';
		};

		self.toggle = function() {
			var targetOffset = 0;
			if(self.isGoUp()) {
				// scroll top
				if(!self.scrolling())
					self.savedOffset(self.currentOffset());
			} else {
				// return
				targetOffset = self.savedOffset();
			}

			self.scrolling(true);

			$("html, body")
				.stop()
				.animate({scrollTop: targetOffset}, {
					duration: self.duration(),
					complete: function() {
						self.scrolling(false);
					}
				});
		};

		self.onScroll = function() {
			self.currentOffset($(window).scrollTop());
		};

		$(window).off('scroll resize', self.onScroll).on('scroll resize', self.onScroll);
	},
	template: "<!--noindex-->\r\n<button class=\"scroll-top\" data-bind=\"html: getLabel(), click: toggle, css: {'up': isGoUp(), 'down': !isGoUp(), 'scrolling': scrolling(), 'scroll-top-visible': show}\"></button>\r\n<!--/noindex-->"
});