!(function($) {
	'use strict';

	// FIXES

	// fix $current.closest(...).size is not a function
	$.fn.size = function() { return this.length; }

	// fix for Android stock browser
	var ua = navigator.userAgent;
	if(ua.indexOf('Mozilla/5.0') > -1 && ua.indexOf('Android ') > -1 && ua.indexOf('AppleWebKit') > -1 && ua.indexOf('Chrome') === -1)
	{
		$('select.form-control').removeClass('form-control').css('width', '100%');
	}

	// apply all common plugins

	$.fn.initPlugins = function() {
		// init bootstrap plugins
		$('[rel="tooltip"],.tip', this).tooltip();
		$('[rel="popover"],.pop', this).popover();

		$("select.selectize", this).removeClass('form-select').removeClass('custom-select');

		$("select[multiple]", this).css({'padding': '0 !important', 'border': '0 !important'}).selectize({
			plugins: ['remove_button']
		});
		$("select.selectize", this).selectize({
			plugins: ['remove_button']
		});


	};

	$(document).ready(function() {
		window.App = new Application(window.__app || {});
		ko.options.deferUpdates = true;
		ko.applyBindings(window.App);

		$(document).initPlugins();
	});

})(jQuery);