ko.bindingProvider.instance.preprocessNode = function(node) {
	if (node.nodeType == 8) {
		// <!-- component: name[, params] -->
		var match = node.nodeValue.match(/^\s*component\s*:\s*([^,]+)[\s,]*(.+)\s*/);
		if (match) {
        	var name = match[1].trim();
        	if (name) {
        		var params = match[2].trim();
				if (params)
					params = ', params: {' + params + '}';
            	var c1 = document.createComment("ko component: {name: '" + name + "'" + params + "}"),
					c2 = document.createComment("/ko");
				node.parentNode.insertBefore(c1, node);
				node.parentNode.replaceChild(c2, node);
				return [c1, c2];
			}
        }
    }
}