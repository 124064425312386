(function($) {
	'use strict';

	// TIPS

	var TIP_TIMEOUT = '_tip_timeout',
		TIP_ACTIVE = '_tip_active',
		_tipHandler = function(e) {
		var element = e.currentTarget,
			$element = $(element),
			tip = $element.data('tip') || {},
			title = tip.title || $element.data('title') || false,
			delay = tip.delay || $element.data('delay') || 800,
			interactive = tip.interactive || $element.data('interactive') || false,
			options = $.extend({
				animation: true,
				html: true,
				trigger: 'manual',
				container: 'body',
				placement: tip.placement || $element.data('placement') || 'top'
			}, tip.options || $element.data('tip-options') || {}),
			loadingClass = tip.loadingClass || $element.data('loading') || 'loading',
			activeClass = tip.activeClass || $element.data('active') || 'active',

			getContent = function(callback) {
				var action = tip.action || $element.data('action') || false,
					url = tip.url || $element.data('url') || false;
				if(!action && !url) {
					callback && callback(tip.content || $element.data('content') || $element.attr('aria-label') || $element.attr('title'));
					return;
				}
				if($element.hasClass(loadingClass))
					return;
				$element.addClass(loadingClass);
				var params = tip.params || $element.data('param') || {};
				if(url) {
					$.ajax({url: url, data: params}).done(function(data) {
						callback && callback(data);
					}).always(function() {
						$element.removeClass(loadingClass);
					});
				} else {
					App.ajaxRequest(action, params).done(function(r) {
						var content = r.message || '';
						if(r.data)
							content = r.data;
						callback && callback(content);
					}).always(function() {
						$element.removeClass(loadingClass);
					});
				}
			},
			dispose = function() {
				if($element.data('bs.popover')) {
					var $tip = $($element.data('bs.popover').getTipElement());
					$tip.off('mouseenter mouseleave');
					$element.off('mouseenter mouseleave');
					$element.popover('dispose');
				}
				$element.removeClass(activeClass).removeClass('has-tip');
			},
			show = function() {
				$element.data(TIP_ACTIVE, true);

				getContent(function(content) {
					if(!$element.data(TIP_ACTIVE))
						return;
					var opts = options,
						titl = content.title || title,
						$div = $('<div/>').html(content.content || content);
					ko.applyBindings(window.App, $div[0]);
					$div.initPlugins();
					$div.find('.has-tip').removeClass('has-tip');

					opts.content = $div;
					if(titl)
						opts.title = titl;

					dispose();

					$element.popover(opts);

					var $tip = $($element.data('bs.popover').getTipElement());

					if(interactive)
						$tip.on('click', '[data-dissmiss="popover"]', hide)
							.on('mouseenter', onEnter)
							.on('mouseleave', onLeave);

					$tip.addClass('popover-animate');

					$element.popover('show');
					$element.addClass(activeClass).addClass('has-tip');

					$tip.addClass('in');
				});
			},
			hide = function() {
				clearTimeout($element.data(TIP_TIMEOUT));
				if($element.data('bs.popover')) {
					var $tip = $($element.data('bs.popover').getTipElement());
					if($tip.find('.has-tip').length) {
						$element.data(TIP_TIMEOUT, setTimeout(hide, 100));
						return;
					}

					$element.data(TIP_ACTIVE, false);

					$tip.removeClass('in').addClass('out');

					setTimeout(dispose, 300);
				}
				$element.data(TIP_ACTIVE, false);
			},
			onEnter = function() {
				clearTimeout($element.data(TIP_TIMEOUT));
				if(!$element.data(TIP_ACTIVE))
					$element.data(TIP_TIMEOUT, setTimeout(show, delay));
			},
			onLeave = function() {
				clearTimeout($element.data(TIP_TIMEOUT));
				if($element.data(TIP_ACTIVE))
					$element.data(TIP_TIMEOUT, setTimeout(hide, 300));
			};

		if(!$element.data('content'))
			$element.data('content', $element.attr('aria-label') || $element.attr('title'));
		$element.removeAttr('aria-label');
		$element.removeAttr('title');

		$element
			.off('mouseenter mouseleave')
			.on('mouseenter', onEnter)
			.on('mouseleave', onLeave);

		onEnter();
	};

	$(document).off('mouseenter', '.tip, [data-tip]', _tipHandler).on('mouseenter', '.tip, [data-tip]', _tipHandler);

})(jQuery);