var User = function(params){
	var self = this;

	self.id = ko.observable(parseInt(params && params.id || 0));
	self.name = ko.observable(params && params.name || 'Guest');
	self.image = ko.observable(params && params.image || '');
	self.settings = ko.observable(params && params.settings || {});

	self.isGuest = ko.pureComputed(function(){ return self.id() === 0; });
};
